import { InboxIcon } from "@heroicons/react/24/outline"
import axios from "axios"
import { Link, navigate } from "gatsby"
import React, { Component } from "react"
import { connect } from "react-redux"
import Button from "../components/base/Button"
import Footer from "../components/base/Footer"
import Header from "../components/base/Header"
import Overlay from "../components/base/Overlay"
import SEO from "../components/base/seo"
import LeftContentCard from "../components/marketing/LeftContentCard"
import DoorImage from "../images/home_ss14.webp"
import { updateReferralId } from "../store/actions/promotionActions"

interface Props {
  updateReferralId: (referralId: string) => void
}

class Referral extends Component<Props> {
  state = {
    loading: true,
    name: "-",
  }

  componentDidMount = async () => {
    const isProd = process.env.GATSBY_FIREBASE_ENV === "production"
    const userDetailsAPILoc = isProd ? "arusoil-web" : "arusoil-web-dev"
    const userDetailsAPI = `https://asia-southeast2-${userDetailsAPILoc}.cloudfunctions.net/getUserDetailsById`

    const searchParams = new URLSearchParams(window.location.search)
    const referralId = searchParams.get("id")

    if (referralId) {
      try {
        const userDetailRes = await axios.post(userDetailsAPI, {
          userId: referralId,
        })
        this.props.updateReferralId(referralId)
        this.setState({
          loading: false,
          name: userDetailRes.data.user.name,
        })
      } catch (err) {
        navigate("/")
      }
    }
  }

  render() {
    return (
      <div className="min-h-screen bg-gray-100">
        <SEO
          title="Arus Oil - Referral Link"
          description="Sign up with this referral link to recycle your used cooking oil at Arus Oil now!"
        />
        <Overlay
          loading={this.state.loading}
          text="Please hold on while we are verifying your referral"
        />
        <Header page="Referrals" />
        <main>
          <div className="py-16 bg-gray-100 overflow-hidden">
            <div className="max-w-7xl mx-auto px-4 space-y-8 sm:px-6 lg:px-8">
              <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
                <div className="relative z-10">
                  <div className="text-base max-w-prose mx-auto lg:max-w-none">
                    <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                      {this.state.name} has invited you to recycle used cooking
                      oil with Arus Oil
                    </p>
                  </div>
                  <div className="text-gray-500 mt-5 max-w-prose mx-auto lg:max-w-none">
                    <p>
                      Arus Oil is an on-demand recycling platform for used
                      cooking oil that enables household and businesses to
                      recycle their used cooking oil conveniently.
                    </p>
                  </div>
                  <div className="mt-10 flex text-base max-w-prose mx-auto lg:max-w-none">
                    <div>
                      <Link to="/auth/signUp">
                        <Button
                          className="mt-10 mb-4 w-full sm:max-w-xs"
                          type="normal"
                          text="Sign Up"
                        />
                      </Link>
                    </div>
                    <div className="ml-4">
                      <Link to="/faqs">
                        <Button
                          className="mt-10 mb-4 w-full sm:max-w-xs"
                          type="light"
                          text="Learn More"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="mt-12 relative text-base max-w-prose mx-auto lg:mt-0 lg:max-w-none">
                  <svg
                    className="absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20"
                    width={404}
                    height={384}
                    fill="none"
                    viewBox="0 0 404 384"
                    aria-hidden="true"
                  >
                    <defs>
                      <pattern
                        id="bedc54bc-7371-44a2-a2bc-dc68d819ae60"
                        x={0}
                        y={0}
                        width={20}
                        height={20}
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x={0}
                          y={0}
                          width={4}
                          height={4}
                          className="text-gray-200"
                          fill="currentColor"
                        />
                      </pattern>
                    </defs>
                    <rect
                      width={404}
                      height={384}
                      fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)"
                    />
                  </svg>
                  <div className="relative bg-arusgreen-500 rounded-lg shadow-lg">
                    <div className="rounded-t-lg px-6 py-8 sm:px-10 sm:pt-10 sm:pb-8">
                      <div className="relative text-lg text-gray-700 font-medium">
                        <p className="text-xl leading-8 font-bold tracking-tight text-white sm:text-2xl">
                          Here is how you can get a 10% increase in value when
                          you recycle your used cooking oil
                        </p>
                        <p className="relative mt-3 text-white">
                          1. Sign up with this personal invite.
                        </p>
                        <p className="relative mt-3 text-white">
                          2. Recycle your used cooking oil for the first time
                          with a minimum of 5kg
                        </p>
                        <p className="relative mt-3 text-white">
                          3. Get a 10% increase of the purchasing rates of your
                          used cooking oil for 3 months
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <LeftContentCard
            image={DoorImage}
            title="Door to Door Collection"
            content="Arus Oil provides a door-to-door collection service to
          collect your used cooking oil (UCO). Simply get started by
          creating an account and place your preferred day, time, and
          address for collection arrangement."
            icon={
              <InboxIcon className="h-6 w-6 text-white" aria-hidden="true" />
            }
          />
        </main>
        <Footer />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateReferralId: (referralId: string) =>
      dispatch(updateReferralId(referralId)),
  }
}
export default connect(null, mapDispatchToProps)(Referral)
